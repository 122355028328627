import axios from 'axios';

let BASE_URL = 'https://api.quizini.com/';

if (process.env.NODE_ENV !== 'production') {
  console.log("DEV MODE");
  BASE_URL = 'http://localhost:5100/';
}

console.log('CUREENT NODE ENV' + process.env.REACT_APP_BOM);
console.log(">AAAAYYY - "+process.env.REACT_APP_ENV)
console.log("REACT_APP_TEST - "+process.env.REACT_APP_TEST)

if (process.env.NODE_ENV === 'TST') {
  console.log("DEV MODE");
  BASE_URL = 'http://tstapi.quizini.com/';
}

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
